.unstoppable {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
}


.unstoppable-ultraWide {
  max-height: 90%;
}
.unstoppable-wide {
  max-height: 85%;
}
.unstoppable-square {
  max-width: 90%;
}
.unstoppable-short {
  width: 95%;
}
.unstoppable-tall {
  width: 100%;
}
.unstoppable-ultraTall {
  width: 100%;
}
