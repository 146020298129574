/* LOGOs */

.bp-xs.logo-sm {
  max-width: 60px !important;
}
.bp-xs.logo-md {
  max-width: 55px !important;
}
.bp-xs.logo-lg {
  max-width: 45px !important;
}
.bp-sm.logo-sm {
  max-width: 60px !important;
}
.bp-sm.logo-md {
  max-width: 55px !important;
}
.bp-sm.logo-lg {
  max-width: 45px !important;
}

.bp-md.logo-sm {
  max-width: 70px !important;
}
.bp-md.logo-md {
  max-width: 65px !important;
}
.bp-md.logo-lg {
  max-width: 65px !important;
}

.bp-lg.logo-sm {
  max-width: 100px !important;
}
.bp-lg.logo-md {
  max-width: 80px !important;
}
.bp-lg.logo-lg {
  max-width: 60px !important;
}

.bp-xl.logo-sm {
  max-width: 120px !important;
}
.bp-xl.logo-md {
  max-width: 120px !important;
}
.bp-xl.logo-lg {
  max-width: 120px !important;
}

.bp-xxl.logo-sm {
  max-width: 180px !important;
}
.bp-xxl.logo-md {
  max-width: 180px !important;
}
.bp-xxl.logo-lg {
  max-width: 180px !important;
}

.avax {
  width: 50%;
}

.bitcoin {
  width: 10%;
}

.cosmos {
  width: 10%;
}

.doge {
  width: 10%;
}

.ethereum {
  width: 10%;
}

.filecoin {
  width: 10%;
}

.osmo {
  width: 10%;
}

.pepe {
  width: 15%;
}
.pepe-lg {
  max-width: 100px !important;
}
.pepe-xl {
  max-width: 100px !important;
}

.platypus {
  width: 10%;
}

.shiba {
  width: 10%;
}

.shitCoin {
  width: 20%;
}

.shitCoin1 {
  width: 20%;
}

.shitCoin2 {
  width: 20%;
}

.shitCoin3 {
  width: 20%;
}

.shitCoin4 {
  width: 20%;
}

.solana {
  width: 8%;
}

.tether {
  width: 10%;
}

.tw {
  width: 20%;
}

.uniswap {
  width: 10%;
}

.xbt {
  width: 10%;
}

.zcsh {
  width: 10%;
}
