html {
  background-color: #50b948;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.follow-toggle {
  position: fixed;
  z-index: 999;
  background-color: transparent;
  height: 50px;
  border-bottom-right-radius: 25px;
  border-left: 0;
  border-top: 0;
  border-bottom-color: red;
  border-right-color: red;
  background-color: orange;
  font-family: "Comic Sans MS", "Chalkboard SE", "Comic Neue", sans-serif;
  padding-right: 10px;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.description-text {
  position: absolute;
  bottom: 3%;
  margin: auto;
  left: 0;
  right: 0;
  font-family: "Comic Sans MS", "Chalkboard SE", "Comic Neue", sans-serif;
  display: block;
}

.description-text-x {
  margin-left: 20%;
  margin-right: 20%;
}
.description-text-y {
  margin-left: 15%;
  margin-right: 15%;
}

.bold {
  font-weight: bold;
}

.logo-container {
  width: 100%;
  height: 100%;
}

.logo-container > img {
  position: absolute;
  max-width: 100px;
  padding-bottom: 10%;
}

.absolute-middle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.dev-note {
  margin-top: 30px;
}
